import React from "react"
import { Col, Form } from "react-bootstrap"
import useWidget from "../../../api/hooks/useWidget"
import { ModuleWidgetsType, ModuleWidgetsWidgetGraphModuleType, ModuleWidgetsWidgetType } from "../../../types/modules"
import ComponentDashboard from "../../components/ComponentDashboard"
import ComponentFilters from "../../components/ComponentFilters"
import { KTSVG } from "../../../../_metronic/helpers"
import ProgressBarModule from "./graphModules/ProgressBar"
import CharModule from "./graphModules/Char"
import DonutModule from "./graphModules/Donut"
import { useFilter } from "../helpers"
import { useIntl } from "react-intl"
import ComponentTooltip from "../../components/ComponentTooltip"
import useUpdate from "../../../api/hooks/useUpdate"
import SplashScreen from "../../helpers/SplashScreen"


const WidgetGraphModule: React.FC<ModuleWidgetsWidgetGraphModuleType> = ({ detail }) => {
    const intl = useIntl()
    const { type, settings } = detail
    switch (type) {
        case "progress_bar":
            return <ProgressBarModule title={settings.title} percent={settings.percent} />
        case "char":
        case "details_char":
            return <CharModule char={settings.char} withDetails={type === "details_char"} value_title={settings.value_title} />
        case "donut":
            return <DonutModule char={settings.char} />
        default:
            return <div className="card-body">
                <div className='d-flex flex-column w-100'>
                    <span className='text-dark me-2 fw-bold pb-3'>{`${intl.formatMessage({ id: "WIDGET.UNDEFINDED_MODULE" })} ${type}`}</span>
                </div>
            </div>
    }
}

const Widget: React.FC<ModuleWidgetsWidgetType> = ({ data }) => {
    const { prefix, value, postfix, description, detail, size = 2, background } = data
    const havePostifx = !Array.isArray(postfix) && postfix.value
    const haveGraphModule = !Array.isArray(detail)
    const widgetSize = size * 3
    return <Form.Group className="moduleWidgets_widgetContainer" as={Col} md={widgetSize}>

        <div className={`moduleWidgets_widget card card-flush ${background ?? ""}`}>
            <div className="moduleWidgets_widgetContent card-header">
                <div className='card-title flex-column mw-100'>
                    <div className="moduleWidgets_widgetValueContainer">
                        <span className="moduleWidgets_widgetValuePrefix">{prefix}</span>
                        <ComponentTooltip title={value}>
                            <span className="moduleWidgets_widgetValue">{value}</span>
                        </ComponentTooltip>
                        {
                            havePostifx ? <span className={`badge badge-light-${postfix?.background ?? "success"} fs-base`}>
                                {postfix?.icon ? <KTSVG
                                    path='/media/icons/duotune/arrows/arr066.svg'
                                    className='svg-icon-5 svg-icon-success ms-n1'
                                /> : null}
                                {' '}
                                {postfix?.value}
                            </span> : null
                        }
                    </div>
                    <span className='moduleWidgets_widgetDescription'>{description}</span>
                </div>
            </div>
            {haveGraphModule ? <WidgetGraphModule detail={detail} /> : null}
        </div>

    </Form.Group>
}

const ModuleWidgets = React.memo<ModuleWidgetsType>((props) => {
    const { components, settings, hook } = props
    const { widgets_group: widgetCommand, filters: initialFilters } = settings
    const { filter, isInitials, setFilter, resetFilter } = useFilter(`${props.type}_${widgetCommand}`, initialFilters)
    const haveFilter = Boolean(components?.filters)
    const { data, isLoading, refetch } = useWidget(widgetCommand, filter)
    useUpdate([{ active: Boolean(hook), update: refetch }], hook, 1000)
    return <div className="moduleWidgets">
        <ComponentDashboard>
            {haveFilter ? <ComponentFilters
                type="dropdown"
                data={components.filters}
                filterValues={filter}
                isInitials={isInitials}
                handleChange={setFilter}
                handleReset={resetFilter}
            /> : null}
        </ComponentDashboard>
        <SplashScreen active={isLoading} />
        {data?.map(widget => <Widget key={widget.description + widget.value} data={widget} />)}
    </div>
})

export default ModuleWidgets