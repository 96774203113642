import React, { useEffect, useState, useContext as useReactContext, useMemo } from "react"
import { Tab, Tabs } from "react-bootstrap"
import PageBuilder from "../../page/PageBuilder"
import { ModuleTabsType } from "../../types/modules"
import { useLocation, useNavigate } from "react-router-dom"
import { ModalContext } from "./ModuleSchedule/ModuleSchedule"
import ComponentHelp from "../components/ComponentHelp"

const ModuleTabs = React.memo<ModuleTabsType>((props) => {
    const { settings } = props

    const resolvedTabs = useMemo(() => {
        if (settings) {
            if (Array.isArray(settings)) {
                return settings.map(tab => ({ ...tab, key: tab.title }))
            } else {
                return Object.entries(settings).map(([article, tab]) => ({ ...tab, key: article }))
            }
        } else {
            return []
        }
    }, [settings])

    const initialKey = resolvedTabs.length ? resolvedTabs[0].key : null
    const [key, setKey] = useState<string | null>(initialKey)
    const navigate = useNavigate()
    const location = useLocation()
    const modalContext = useReactContext<any>(ModalContext)

    const isTabsInsideModal = Boolean(modalContext.insideModal)

    const handleChangeTab = (key: string | null) => {
        const queryString = isTabsInsideModal && location.search ? location.search.replace(/&tab=.*/, "") + `&tab=${key}` :  `?tab=${key}`
        navigate(queryString)
        setKey(key)
    }


    const handleSelectTab = (key: string | null, event: React.SyntheticEvent<unknown>) => {
        if (event.target instanceof Element && (event.target.closest(".componentHelp") || event.target.closest(".componentHelp_modal"))) {
            return
        } else {
            handleChangeTab(key)
        }
      
    }

    useEffect(() => {
        if (settings.length && key !== initialKey) {
            handleChangeTab(initialKey)
        }
    }, [settings])
    
    return <div>
        <Tabs
            id="moduleTabs"
            activeKey={key as string | undefined}
            onSelect={handleSelectTab}
            className="mb-3"
            mountOnEnter
            unmountOnExit

        >
            {resolvedTabs.map(tab => <Tab key={tab.key} eventKey={tab.key}
             title={
             <div className="moduleTabs_tabTitle">{tab.title}
             {tab.counter ? <span className="moduleTabs_tabCounter badge badge-primary">{tab.counter}</span> : null}
             {tab.is_help ? <ComponentHelp tab={tab.key} /> : null}
             </div>}>
                <PageBuilder data={tab.body} isFetching={false} showProgressBar={false} />
            </Tab>)}
        </Tabs>
    </div>
})

export default ModuleTabs