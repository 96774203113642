import { toAbsoluteUrl } from '../../../_metronic/helpers'


export const setDocumentTitle = () => {
  const url = window.location.hostname.split(".")
  const currentDomain = url.length === 3 ? url[1] : url[0]
  /* субдомен не нужен в дальнейшем, только для текущей реализации задачника */
  const currentSubdomain = url.length === 3 ? url[0] : null
  const favicon = document.getElementById("favicon") as HTMLLinkElement
  switch (currentDomain) {
    case "meetor":
      document.title = "Meetor. Онлайн-запись для бизнеса"
      return favicon.href = toAbsoluteUrl("media/crm/favicon/meetor.ico")
    case "ищу-найду":
    default:
      return
  }
}
export const getBackground = () => {
  const url = window.location.hostname.split(".")
  const currentDomain = url.length === 3 ? url[1] : url[0]
  /* субдомен не нужен в дальнейшем, только для текущей реализации задачника */
  const currentSubdomain = url.length === 3 ? url[0] : null
  switch (currentDomain) {
    case "ищу-найду":
    default:
      return "look_find.jpg"
  }
}

export const getLogo = () => {
  const url = window.location.hostname.split(".")
  const currentDomain = url.length === 3 ? url[1] : url[0]
  /* субдомен не нужен в дальнейшем, только для текущей реализации задачника */
  const currentSubdomain = url.length === 3 ? url[0] : null
  switch (currentDomain) {
    case "meetor":
      return {
        light: "meetor.svg",
        dark: "meetor.svg",
      }
    case "ищу-найду":
    default:
      return {
        light: "look_find_light.svg",
        dark: "look_find_dark.svg",
      }
  }
}

export const setStyles = async (theme?: "2023" | string) => {
  switch (theme) {
    case "2023":
      localStorage.setItem("data-app-theme", "theme_1")
      document.documentElement.setAttribute("data-app-theme", "theme_1")
      break
    default:
      localStorage.setItem("data-app-theme", "")
      document.documentElement.removeAttribute("data-app-theme")
  }
}