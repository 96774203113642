import { useQuery } from "react-query"
import api from ".."
import { ApiPageType, ApiResponseType } from "../../types/api"
import { getErrorToast } from "../../constructor/helpers/toasts"


const useInfoPage = (page: string, tab?: string) => {

    const fetchKey = ["page-info", { page, tab }]

    const fetchFunction = () => api<string>("pages", "get-info", { page, tab })

    const hookConfiguration = {
        enabled: Boolean(page),
        retry: false,
        /*     keepPreviousData: true, */
        refetchOnWindowFocus: false,
        select: (data: ApiResponseType<string>) => data.data,
        onError: (error: { message: string }) => getErrorToast(error.message)
    }
    const { isLoading, isFetching, error, data } = useQuery(fetchKey, fetchFunction, hookConfiguration)
    return { isLoading, isFetching, error, data }
}

export default useInfoPage