import { useState } from "react"
import { KTSVG } from "../../../../_metronic/helpers"
import { Modal } from "react-bootstrap"
import setModalIndex from "../../helpers/setModalIndex"
import useInfoPage from "../../../api/hooks/useInfoPage"
import parse from "html-react-parser"
import { unescape } from "lodash"
import { useLocation } from "react-router-dom"

const ComponentHelp: React.FC<{ tab?: string }> = ({ tab }) => {
    const location = useLocation()
    const sectionArticle = location.pathname.slice(1).split("/")[0]
    const [show, setShow] = useState(false)
    const handleButtonClick = () => setShow(true)
    const { data } = useInfoPage(sectionArticle, tab)
    return <>
        <button className="componentHelp" onClick={handleButtonClick}>
            <KTSVG path='/media/crm/icons/question.svg' />
        </button>
        <Modal
            size="lg"
            dialogClassName="customModal"
            className="componentHelp_modal"
            show={show && Boolean(data)}
            onHide={() => setShow(false)}
            onEntering={setModalIndex}
        >
            <Modal.Header closeButton className="modal-emptyHeader" />
            <Modal.Body className="scroll-y">
                {parse(unescape(data))}
            </Modal.Body>
        </Modal>
    </>
}

export default ComponentHelp